// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
require("./sweet_alert_confirm")

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import jQuery from "jquery";
import CubeHandler from "cubeable";
import "bootstrap/js/dist/tooltip";

import Rails from "@rails/ujs"

require("theme/js/smoothscroll.min");
window.cubeable = new CubeHandler;

$(document).ready(function() {
  $('html, body').animate({ scrollTop: 0 }, 'slow');

  var notifications = $('.notifications');
  const loader = '\
    <li class="w-100 load-spinner">\
      <div class="d-flex w-100 justify-content-center pb-3">\
        <i class="fas fa-spinner"></i>\
      </div>\
    </li>\
  ';

  notifications.on('scroll', function(){
    var notif_obj = notifications[0];
    var notif_elem = $(this);
    if(notif_elem.scrollTop() + notif_obj.clientHeight >= notif_obj.scrollHeight) {
      

      var current_page = parseInt(notif_elem.attr('data-current'));
      var last_page = parseInt(notif_elem.attr('data-last'));

      var not_loading = notif_elem.find('.fa-spinner').length < 1
      
      if(current_page < last_page && not_loading) {
        Rails.ajax({
          url: "/users/notifications",
          type: 'get',
          dataType: 'script',
          data: $.param({page: current_page + 1})
        })

        notif_elem.append(loader);
      }
    }
  })
});