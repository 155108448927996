import consumer from "./user_consumer"
import "bootstrap-notify/bootstrap-notify.min";

import "../../../vendor/theme/js/jquery.counterup.min";
import "../../../vendor/theme/js/waypoints.min";


class UserChannelHandler {
  constructor() {
    this.notifies = new Array
    this.connected = this.connected.bind(this)
    this.disconnected = this.disconnected.bind(this)
    this.received = this.received.bind(this)
  }

  connected() {
    
  }

  disconnected() {
    
  }

  received(data) {
    var signed = $(`.signed-${data.reference}`);
  
    if(signed.length == 1) {
      this.toast(data.notif);
      this.updateFollowerNum(data.type);
      this.updateNotifList(
        data.notif_count, 
        data.elem, 
        data.total_notif_count, 
        data.last_page
      );
    }
  }


  updateNotifList(count, elem, total_count, last) {
    var notifs_holder = $('ul.notifications');

    if(notifs_holder.length < 1) {
      let notifs_count_btn = `
        <button class="notification-btn profile-btn ghost-btn position-relative">
          <i class="fas fa-bell mr-0"></i>
          <span class="notifs-count badge badge-dark rounded-circle">
            ${count}
          </span>
        </button>
      `;

      var notifs_holder_elem = `
        <ul class="dropdown-menu-col-1 dropdown-menu-col-2 p-0 d-block notifications" data-current="1" data-last="1">
        </ul>
      `;


      $('.nav-action-elements .site-nav .site-menu').prepend(`
        <li>
          ${notifs_count_btn}
          ${notifs_holder_elem}
        </li>
      `);


      notifs_holder = $('ul.notifications');

    } else $('.notifs-count.badge').text(count)

    if(total_count > 10)
      notifs_holder.children('li:last-child').remove();

    notifs_holder.prepend(elem);

    notifs_holder.attr('data-last', last)
  }


  updateFollowerNum(type) {
    if(type != "portfolio_follower" || type != "user_follower") return

    let follower_elem = $("#portfolio-follower.counter");
    let user_follower_elem = $("#follower.counter");
  
    if(follower_elem.length == 1){
      var follower_elem_count = parseInt(follower_elem.data('num')) + 1;
      follower_elem.data('num', follower_elem_count);
      follower_elem.counterUp({
        delay: 10,
        time: 400
      });
    }

    if(user_follower_elem.length == 1) {
      var user_follower_elem_count = parseInt(user_follower_elem.data('num')) + 1;
      user_follower_elem.data('num', user_follower_elem_count);
      user_follower_elem.counterUp({
        delay: 10,
        time: 400
      });
    }
  }


  toast(notif) {
    var notifies = this.notifies
    let notify = $.notify({
      icon: notif.image,
      message: notif.message
    },{ 
      type: 'info',
      icon_type: 'image',
      allow_dismiss: false,
      newest_on_top: false,
      mouse_over:  false,
      showProgressbar:  false,        
      timer: 2000,
      placement: {
          from: "bottom", 
          align: "right"
      },
      offset: {
          x: 30, y: 30
      },
      delay: 1000,
      z_index: 10000,
      animate: {
        enter: 'animated bounceIn',
        exit: 'animated bounceOut'
      },
      template: `
        <div data-notify="container" class="notify-continer col-xs-11 col-sm-3 alert alert-info" role="alert">
          <div class="media p-2 pb-0">
            <img src="${notif.image}">
            <div class="media-body m-0">
              <h5 class="ml-3 my-1 h3">${notif.name}</h5>
              <p class="ml-3 mb-0 h5">${notif.message}</p>
            </div>
          </div>
      `
    });

    this.notifies.push(notify);
    
    if(this.notifies.length == 4) {
      this.notifies[0].close();
      this.notifies.shift();
    }
  }
}


consumer.subscriptions.create(
  { channel: "UserChannel", room: "notifications" }, 
  new UserChannelHandler()
);