export default class CubeHandler {
  constructor() {
  }

  cube_adustments(cube) {
    var cube_item, cube_item_width, translateZ;

    if (cube.length > 0) {
      cube_item = cube.find('.cube-item');
      cube_item_width = cube_item.innerWidth();
      cube.find('.cube-item').height(cube_item_width);
      cube_item.find('.cube-img').height(cube_item_width);
      cube_item.find('.description').height(cube_item_width);
      translateZ = cube_item_width / 2;

      $('.gallery-box-layout2').attr("style", "transform: translate3d(0px, 0px, " + (-translateZ) + "px);");
      $('.cube-img').attr("style", "transform: rotateY(0deg) translateZ(" + translateZ + "px)");
      $('.description').attr("style", "transform: rotateX(-90deg) translateZ(" + translateZ + "px)");
      
      cube_item.hover(function() {
        var item_width;
        item_width = $(this).width() / 2;
        $(this).find('.gallery-box-layout2').attr("style", "transform: matrix3d(1,0,0,0,0,0,1,0,0,-1,0,0,0,0," + (-item_width) + ",1);");
      }).mouseleave(function() {
        var item_width;
        item_width = $(this).width() / 2;
        $(this).find('.gallery-box-layout2').attr("style", "transform: translate3d(0px, 0px, " + (-item_width) + "px);");
      });
    }
  }
}