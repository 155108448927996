import Rails from '@rails/ujs'
import swal from 'sweetalert2/dist/sweetalert2.min'

const handleConfirm = function(element) {
  if (!allowAction(this)) {
    Rails.stopEverything(element)
  }
}

const allowAction = element => {
  if (element.getAttribute('data-confirm-swal') === null) {
    return true
  }

  showConfirmationDialog(element)
  return false
}

// Display the confirmation dialog
const showConfirmationDialog = element => {
  const message = element.getAttribute('data-confirm-swal')
  const text = element.getAttribute('data-text')

  Swal.fire({
    buttonsStyling: false,
    title: message || 'Are you sure?',
    text: text || '',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Yes, proceed',
    cancelButtonText: 'No, cancel',
    customClass: {
      confirmButton: "btn btn-danger p-3 mr-2",
      cancelButton: "btn btn-success p-3 ml-2"
    }
  }).then(result => confirmed(element, result))
}

const confirmed = (element, result) => {
  if (result.value) {
    // User clicked confirm button
    element.removeAttribute('data-confirm-swal')
    element.click()
  }
}


Rails.delegate(document, '*[data-confirm-swal]', 'click', handleConfirm)
